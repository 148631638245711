import React, { useState } from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import '../css/project.css'
import { Route, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLocationArrow, faEye, faVideo } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser'
import ProjectsConstants from './ProjectsConstants'
import VideoPlayer from './VideoPlayer'


const Projects = () => {

    $('.navbar ul > *').removeAttr('id')
    $('.navbar ul > *:nth-child(3)').attr('id', 'active')

    const { t } = useTranslation()

    const [video, setVideo] = useState('')
    const [image, setImage] = useState('')

    const allProjects = ProjectsConstants()

    return(
        <div className="project">
            <Route exact path="/projects/player">
                <VideoPlayer video={video} />
            </Route>
            <h3>{ t('Menu.1') }</h3>
            <div className="background-project">
                <div className="background-opacity-project">
                    {
                        allProjects.map((item, key) => 
                            <div className="project-card" key={key}>
                                <div className="project-main">
                                    <div className="project-image">
                                        <img src={ item.img } alt="project-img" className={ (item.type === 'pc' ? 'pc' : 'mobile') } />
                                    </div>
                                    <div className="project-info">
                                        <div className="project-title">
                                            <h4>{ item.name }</h4>
                                            <h5>{ item.description }</h5>
                                        </div>
                                        <ul>
                                            {
                                                item.info.map((info, key) => 
                                                    <li key={key}><FontAwesomeIcon icon={ faLocationArrow } /> { ReactHtmlParser (info) }</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="project-details">
                                    <h4>{ item.date }</h4>
                                    <div className="project-demo">
                                        {
                                            item.demo.map((demo, key) => (demo.type === 'github') ? (
                                                <FontAwesomeIcon key={key} className="demo" icon={ faGithub } onClick={ () => { window.open(demo.link,'_blank') } } />
                                            ) : (demo.type === 'live') ? (
                                                <FontAwesomeIcon key={key} className="demo" icon={ faEye } onClick={ () => { window.open(demo.link,'_blank') } } />
                                            ) : (demo.type === 'video') ? (
                                                <Link key={key} to="/projects/player" style={{color: '#fff'}} onClick={() => { setVideo(demo.link); setImage(item.img) }}><FontAwesomeIcon className="demo" icon={ faVideo } /></Link>
                                            ) : '')
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Projects