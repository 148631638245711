import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt, faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'


const ServicesHome = () => {

    const { t } = useTranslation()
    
    return(
        <div className="background-services-home">
            <div className="services-home">
                <div className="h2-background">
                    <h2>{ t('Menu.4') }</h2>
                </div>
                <div className="services-cards">
                    <div className="services-card">
                        <FontAwesomeIcon icon={ faDesktop } className="services-fontawesome" size="3x" />
                        <div className="services-card-info">
                            <h3>{ t('Homepage.Services.1') }</h3>
                            <ul>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.2') }</li>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.3') }</li>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.4') }</li>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.5') }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="services-card">
                        <FontAwesomeIcon icon={ faMobileAlt } className="services-fontawesome" size="3x" />
                        <div className="services-card-info">
                            <h3>{ t('Homepage.Services.6') }</h3>
                            <ul>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.7') }</li>
                                <li><FontAwesomeIcon icon={ faLocationArrow } />{ t('Homepage.Services.5') }</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesHome