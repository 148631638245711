import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
import * as Constants from './Contacts'

const Footer = () => {
    return (
        <footer>
            <div className="footer-card">
                <a href={ Constants.LINKEDIN } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faLinkedin } id="fontawesome-footer" /></a>
            </div>
            <div className="footer-card">
                <a href={ Constants.INSTAGRAM } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faInstagram } id="fontawesome-footer" /></a>
            </div>
            <div className="footer-card">
                <a href={ 'mailto:' + Constants.EMAIL }><FontAwesomeIcon icon={ faAt } id="fontawesome-footer" /></a>
            </div>
            <div className="footer-card">
                <a href={ Constants.WHATSAPP } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faWhatsapp } id="fontawesome-footer" /></a>
            </div>
        </footer>
    )
}

export default Footer