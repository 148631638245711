import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import storage from 'local-storage-fallback'
import { createGlobalStyle } from 'styled-components'

const colorDark = '#6d6d6d'
const colorGrey = '#2B2926'
const colorLight = '#0052A3'
const colorWhite = '#fff'

const GlobalStyle = createGlobalStyle`
#root{
    background-color: ${props => props.theme.mode === 'dark' ? colorGrey : colorLight};
}
.about-cards h3{
    background-color: ${props => props.theme.mode === 'dark' ? colorDark : colorWhite};
}
.services-home .h2-background{
    background-color: ${props => props.theme.mode === 'dark' ? colorDark : colorLight};
}
.services-home .h2-background h2{
    color: ${props => props.theme.mode === 'dark' ? colorLight : colorWhite};
}
.services-card{
    background-color: ${props => props.theme.mode === 'dark' ? colorDark : colorLight};
}
.skills{
    background: ${props => props.theme.mode === 'dark' ? `linear-gradient(to right, ${colorGrey} 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 75%,${colorGrey} 100%)` : `linear-gradient(to right, ${colorLight} 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 75%,${colorLight} 100%)`};
}
.card-skill{
    color: ${props => props.theme.mode === 'dark' ? colorDark : colorLight};
}
footer::before{
    background: ${props => props.theme.mode === 'dark' ? `linear-gradient(to right, ${colorGrey} 0%,${colorDark} 25%, ${colorDark} 50%,${colorDark} 75%, ${colorGrey} 100%)` : `linear-gradient(to right, ${colorLight} 0%,${colorWhite} 25%, ${colorWhite} 50%,${colorWhite} 75%, ${colorLight} 100%)`};
}
.navbar{
    background-color: ${props => props.theme.mode === 'dark' ? colorDark : colorWhite};
    box-shadow: ${props => props.theme.mode === 'dark' ? '0 5px 10px #3b3937' : '0 5px 10px #a6c8e7'};
}
.footer-card a{
    color: ${props => props.theme.mode === 'dark' ? colorDark : colorWhite};
}
.services-home{
    background-color: ${props => props.theme.mode === 'dark' ? 'rgba(59, 57, 55, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
}
.background-about-opacity{
    background-color: ${props => props.theme.mode === 'dark' ? 'rgba(59, 57, 55, 0.9)' : 'rgba(255, 255, 255, 0.9)'};
}
.background-opacity-project{
    background-color: ${props => props.theme.mode === 'dark' ? 'rgba(59, 57, 55, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
}
`

const DarkMode = () => {

    var darkmodeLock = false;
    const darkmode = () => {
        if(!darkmodeLock){
            const ball = $('.ball')
            if(ball.css('transform').valueOf() === 'none')
                ball.css({transform: 'translateX(24px)'})
            else
                ball.css({transform: ''})
            darkmodeLock = true
            setTimeout(darkmodeUnlock, 900)
        }
    }

    const darkmodeUnlock = () => {
        darkmodeLock = false
        changemode()
    }

    const changemode = () => {
        setTheme(theme.mode === 'dark' ? { mode: 'light' } : { mode: 'dark' })
    }

    const getInitialTheme = () => {
        const saveTheme = storage.getItem('theme')
        return saveTheme ? JSON.parse(saveTheme) : { mode: 'light' }
    }
    const [theme, setTheme] = useState(getInitialTheme)
    useEffect(()=>{
        storage.setItem('theme', JSON.stringify(theme))
        if(theme.mode === 'dark')
            $('.ball').css({transform: 'translateX(24px)'})
    }, [theme])

    return(
        <div className="darkmode" onClick={ darkmode }>
            <GlobalStyle theme={theme} />
            <FontAwesomeIcon icon={ faMoon } className='fa-moon' />
            <FontAwesomeIcon icon={ faSun } className='fa-sun' />
            <div className='ball'></div>
        </div>
    )
}

export default DarkMode