import React from 'react'
import Logo from '../img/logo.png'
import styled, { keyframes } from 'styled-components'
import { tada } from 'react-animations'
const Tada = styled.div`animation: 4s ${keyframes`${tada}`} infinite`

const LoadingPage = () => {
    return(
        <div className="loading">
            <Tada>
                <img src={ Logo } alt="logo"/>
            </Tada>
        </div>
    )
}

export default LoadingPage