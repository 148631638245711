import React, { useRef } from 'react'
import Profile from '../img/profile.jfif'
import '../css/cv.css'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faAt, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import * as Constants from './Contacts'
import * as Skills from './SkillsConstants'
import ProjectsConstants from './ProjectsConstants'
import { useReactToPrint } from 'react-to-print'

const Cv = () => {

    const { t } = useTranslation()

    const download = useRef(null)
    const handleDownload = useReactToPrint({
        content: () => download.current,
        documentTitle: "Valdemar Berenguer CV"
    })

    const cardInfo = (title, info, link = false) => {
        return (
            <div className="card-info">
                <h3>{ title }</h3>
                {info.map((text, key) => {
                    if (link)
                        return (
                            <a key={key} href={ text }>{ text }</a>
                        )
                    else
                        return (
                            <p key={key}>{ text }</p>
                        )
                })}
            </div>
        )
    }

    const timeCard = (title, subtitle, date, info, demo = []) => {
        return (
            <div className="time-card">
                <div className="card-content">
                    <h3>{ title }</h3>
                    <h4>{ subtitle }</h4>
                    { typeof info !== 'undefined' && typeof info.length === 'undefined' &&
                    <ul>
                        {
                            Object.values(info).map((el, key) => (
                                <li key={key}>{ el }</li>
                            ))
                        }
                    </ul> }
                    {
                        Array.isArray(info) && 
                        <ul>
                            {
                                info.map((el, key) => (
                                    <li key={key}>{ ReactHtmlParser (el) }</li>
                                ))
                            }
                        </ul>
                    }
                    { typeof info !== 'undefined' && typeof info.length !== 'undefined' && !Array.isArray(info) &&
                        <a>{ info }</a>
                    }
                </div>
                <div>
                    <a>{ date }</a>
                    <div className="github">
                        {
                            demo.map((el, key) => {
                                if (el.type === 'github')
                                    return <a key={key} href={ el.link } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faGithub } /></a>
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    const allProjects = ProjectsConstants()

    return (
        <div>
            <div className="cv" ref={ download }>
                <div className="left">
                    <img src={ Profile } alt="" />
                    <h2>{ t('CV.personal' )}</h2>
                    { cardInfo(t('Homepage.About.2'), ['Joao Valdemar Sousa Berenguer']) }
                    { cardInfo(t('CV.birthDate'), ['01-09-1998']) }
                    { cardInfo(t('CV.nationality'), [t('CV.portuguese')]) }
                    { cardInfo('Website', ['https://zlval.pt'], true) }
                    <div className="cv-contacts">
                        <a href={ Constants.LINKEDIN } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faLinkedin } /></a>
                        <a href={ Constants.INSTAGRAM } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faInstagram } /></a>
                        <a href={ Constants.EMAIL } ><FontAwesomeIcon icon={ faAt } /></a>
                        <a href={ Constants.WHATSAPP } target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={ faWhatsapp } /></a>
                    </div>
                </div>
                <div className="leftPDF"></div>
                <div className="main">
                    <div className="card">
                        <h2>{ t('Homepage.About.1') }</h2>
                        {
                            Object.values(t('Home.info', { returnObjects: true })).map((text, key) => (
                                <p key={key}>{ text }</p>
                            ))
                        }
                    </div>
                    <div className="card">
                        <h2>{ t('Home.8') }</h2>
                        {
                            Object.values(t('Home.experience', { returnObjects: true })).map((text, key) => (
                                <div key={key}>
                                    { timeCard(text.title, text.subtitle, text.date, text.info) }
                                </div>
                            ))
                        }
                    </div>
                    <div className="card">
                        <h2>{ t('Home.5') }</h2>
                        {
                            Object.values(t('Home.formation', { returnObjects: true })).map((text, key) => (
                                <div key={key}>
                                    { timeCard(text.title, text.subtitle, text.date, text.info) }
                                </div>
                            ))
                        }
                    </div>
                    <div className="card">
                        <h2>{ t('Menu.2') }</h2>
                        {
                            Object.keys(Skills.SKILLS).map((skill, key) => (
                                <div className="skill-card" key={key}>
                                    <a>{ skill }</a>
                                    <progress value={Skills.SKILLS[skill]} max="5"></progress>
                                </div>
                            ))
                        }
                    </div>
                    <div className="card" style={{ paddingBottom: '15px' }}>
                        <h2>{ t('Menu.1') }</h2>
                        {
                            Object.keys(allProjects).map((project, key) => (
                                <div key={key}>
                                    { timeCard(allProjects[project].name, allProjects[project].description, allProjects[project].date, allProjects[project].info, allProjects[project].demo) }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="button-pdf" title="Download" onClick={ handleDownload }>
                <FontAwesomeIcon icon={ faArrowCircleDown } />
            </div>
        </div>
    )
}

export default Cv