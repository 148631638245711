import React, { useEffect, useState } from 'react'
import Logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChangeLanguage from './ChangeLanguage'
import DarkMode from './DarkMode'
import styled, { keyframes } from 'styled-components'
import { tada } from 'react-animations'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Tada = styled.div`animation: 4s ${keyframes`${tada}`} infinite`

const NavBar = () => {

    const verificaPath = () => {
        if(window.location.pathname === '/about' || window.location.pathname === '/about/'){
            $('.navbar ul > *:nth-child(1)').attr('id', 'active')
        }else if(window.location.pathname === '/skills' || window.location.pathname === '/skills/'){
            $('.navbar ul > *:nth-child(2)').attr('id', 'active')
        }else if(window.location.pathname === '/projects' || window.location.pathname === '/projects/'){
            $('.navbar ul > *:nth-child(3)').attr('id', 'active')
        }
    }

    const verificaClick = (e, page) => {
        if((window.location.pathname === '/about' || window.location.pathname === '/about/') && page === 'about'){
            e.preventDefault()
        }else if((window.location.pathname === '/skills' || window.location.pathname === '/skills/') && page === 'skills'){
            e.preventDefault()
        }else if((window.location.pathname === '/projects' || window.location.pathname === '/projects/') && page === 'projects'){
            e.preventDefault()
        }else if(window.location.pathname === '/' && page === 'home'){
            e.preventDefault()
        }else{
            if(menuOpen){
                menu()
            }
        }
    }

    const [ menuOpen, setMenuOpen ] = useState(false)
    const menu = () => {
        if(menuOpen){
            $('.navbar ul').removeAttr('id')
            $('.settings').removeAttr('id')
            $('.navbar').removeClass('active')
        }else{
            $('.navbar ul').attr('id', 'open')
            $('.settings').attr('id', 'open')
            $('.navbar').addClass('active')
        }
        setMenuOpen(!menuOpen)
    }

    const { t } = useTranslation()

    useEffect(() => {
        if(window.location.pathname === '/about' || window.location.pathname === '/about/'){
            document.querySelector('.menu-about').id = 'active'
        }else if(window.location.pathname === '/skills' || window.location.pathname === '/skills/'){
            document.querySelector('.menu-skills').id = 'active'
        }else if(window.location.pathname === '/projects' || window.location.pathname === '/projects/'){
            document.querySelector('.menu-projects').id = 'active'
        }
    }, [])

    return (
        <div className='navbar'>
            <Tada>
                <Link to="/" style={{ cursor: 'default' }} onClick={ e => { verificaClick(e, 'home') } } ><img className="img-logo" src={ Logo } alt="Logo"/></Link>
            </Tada>
            <ul>
                <Link onMouseLeave={ verificaPath } onMouseEnter={ () => { $('.navbar ul > *').removeAttr('id') } } to="/about" className="menu-about"><li onClick={ e => { verificaClick(e, 'about') } }>{ t('Menu.3') }</li></Link>
                <Link onMouseLeave={ verificaPath } onMouseEnter={ () => { $('.navbar ul > *').removeAttr('id') } } to="/skills" className="menu-skills"><li onClick={ e => { verificaClick(e, 'skills') } }>{ t('Menu.2') }</li></Link>
                <Link onMouseLeave={ verificaPath } onMouseEnter={ () => { $('.navbar ul > *').removeAttr('id') } } to="/projects" className="menu-projects" ><li onClick={ e => { verificaClick(e, 'projects') } }>{ t('Menu.1') }</li></Link>
                <a onMouseLeave={ verificaPath } onMouseEnter={ () => { $('.navbar ul > *').removeAttr('id') } } href="/cv" target="_blank" rel="noopener noreferrer"><li>CV</li></a>
            </ul>
            <div className='settings'>
                <DarkMode />
                <ChangeLanguage />
            </div>
            <div className="burguer" onClick={ menu }>
                <FontAwesomeIcon icon={ faBars } />
            </div>
        </div>
    )
}

export default NavBar