export const SKILLS = {
    'HTML': 5,
    'CSS': 4,
    'PHP': 5,
    'JavaScript': 5,
    'Laravel': 4,
    'Symfony': 5,
    'SQL': 5,
    'MongoDB': 4,
    'RedisDB': 3,
    'Microsoft Visual Studio': 5,
    'PHP Storm': 5,
    'Greenfoot': 5,
    'Netbeans': 5,
    'GitHub': 4,
    'GitLab': 4,
    'Java': 5,
    'C': 5,
    'C++': 5,
    'C#': 5,
    'Source Pawn': 4,
    'Python': 4,
    'Mathematica': 5,
    'Assembly': 5,
    'React': 4,
    'VB.NET': 1,
    'JIRA': 4,
    'Postman': 4
}