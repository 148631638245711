import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as noStar } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'

const SkillsHome = () => {

    const { t } = useTranslation()

    return(
        <div className="skills-home">
            <div className="h2-background">
                <h2>{ t('Homepage.Skills.1') }</h2>
            </div>
            <div className="skills">
                <div className="card-skill">
                    <h3>PHP</h3>
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                </div>
                <div className="card-skill">
                    <h3>SQL</h3>
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                </div>
                <div className="card-skill">
                    <h3>Javascript</h3>
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                </div>
                <div className="card-skill">
                    <h3>Java</h3>
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ noStar } />
                </div>
                <div className="card-skill">
                    <h3>C++</h3>
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ faStar } />
                    <FontAwesomeIcon icon={ noStar } />
                </div>
            </div>
        </div>
    )
}

export default SkillsHome