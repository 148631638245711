import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as noStar } from '@fortawesome/free-regular-svg-icons'
import '../css/skills.css'
import * as skills from './SkillsConstants'
import $ from 'jquery'

const Skills = () => {

    $('.navbar ul > *').removeAttr('id')
    $('.navbar ul > *:nth-child(2)').attr('id', 'active')

    const { t } = useTranslation()

    const loadStars = (numberStars) => {
        let html = [];
        for (let i = 0; i < 5; i++) {
            if (numberStars === 0) {
                html.push(<FontAwesomeIcon icon={ noStar } key={i} />)
            } else {
                numberStars--;
                html.push(<FontAwesomeIcon icon={ faStar } key={i} />)
            }
        }
        return html;
    }

    return (
        <div className="skill">
            <h3>{ t('Menu.2') }</h3>
            <div className="background-skills">
                <div className="all-skills">
                    {
                        Object.keys(skills.SKILLS).map((skill, key) => {
                            return (
								<div className="skills-card" key={key}>
									<h4>{ skill }</h4>
									{loadStars(skills.SKILLS[skill])}
								</div>
							)
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Skills