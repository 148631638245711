import React, { useEffect, useState } from 'react'
import storage from 'local-storage-fallback'
import { useTranslation } from 'react-i18next'
import EN from '../img/language/en.png'
import PT from '../img/language/pt.png'


const ChangeLanguage = () => {

    const { i18n } = useTranslation()

    const getInitialLanguage = () => {
        const saveLanguage = storage.getItem('language')
        return saveLanguage ? JSON.parse(saveLanguage) : { lang: 'en' }
    }

    const [language, setLanguage] = useState(getInitialLanguage)

    useEffect(() => {
        storage.setItem('language', JSON.stringify(language))
        i18n.changeLanguage(language.lang)
    }, [i18n, language])

    const handleClick = () => {
        if(i18n.language === 'pt'){
            setLanguage({lang: 'en'})
        }
        else{
            setLanguage({lang: 'pt'})
        }
    }

    return(
        <img src={ language.lang === 'pt' ? PT : EN } alt="language" onClick={ handleClick } />
    )
}

export default ChangeLanguage