import React from 'react'
import '../css/404.css'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {

    const { t } = useTranslation()

    return(
        <div className="error">
            <div className="h2-background">
                <h2>404</h2>
            </div>
            <h3>{ t('Error.1') }</h3>
            <h4>{ t('Error.2') }</h4>
        </div>
    )
}

export default NotFoundPage