import React from 'react'
import Profile from '../img/profile.jfif'
import { useTranslation } from 'react-i18next'
import moment from "moment"
import $ from 'jquery'

const AboutHome = () => {

    $('.navbar ul > *').removeAttr('id')

    const { t } = useTranslation()

    var currentDate = {day: moment().format("DD"), month: moment().format("MM"), year: moment().format("YYYY")}
    const getAge = () => {
        var birthDate = {day: "01", month: "09", year: "1998"}
        var months = currentDate.month - birthDate.month
        if(months < 0 || (months === 0 && currentDate.day < birthDate.day)){
            return (currentDate.year - birthDate.year) - 1
        }
        return currentDate.year - birthDate.year
    }

    return(
        <div className="about-home">
            <img src={ Profile } alt="profile"/>
            <div className="about-text">
                <div className='h2-background'>
                    <h2>{ t('Homepage.About.1') }</h2>
                </div>
                <div className="about-cards">
                    <div>
                        <p>João Valdemar Sousa Berenguer</p>
                        <h3>{ t('Homepage.About.2') }</h3>
                    </div>
                    <div>
                        <p>{ getAge() } { t('Homepage.About.4') }</p>
                        <h3>{ t('Homepage.About.3') }</h3>
                    </div>
                    <div>
                        <p>Madeira, Funchal</p>
                        <h3>{ t('Homepage.About.5') }</h3>
                    </div>
                    <div>
                        <p>{ t('Homepage.About.7') }</p>
                        <h3>{ t('Homepage.About.6') }</h3>
                    </div>
                </div>
                <div className="about-info">
                    <p>{ t('Homepage.About.8') }</p>
                    <p>{ t('Homepage.About.9') }</p>
                    <p>{ t('Homepage.About.10') }</p>
                    <p>{ t('Homepage.About.11') }</p>
                </div>
            </div>
        </div>
    )
}

export default AboutHome