import { useTranslation } from 'react-i18next'
import Expresso from '../img/projects/expresso.png'
import FiftyOneMedia from '../img/projects/fiftyonemedia.png'
import PoolPrime from '../img/projects/poolprime.png'
import VendingMachine from '../img/projects/vendingmachine.png'
import Portfolio from '../img/projects/portfolio.png'

const ProjectsConstants = () => {
    const { t } = useTranslation()

    const getTecnologies = (tecnologies) => {
        return `<strong>${ t('Projects.tecnologies') }</strong>${ tecnologies }`
    }

    const tProject6 = t('Projects.allProjects.6', { returnObjects: true })
    tProject6.info.push(getTecnologies('React, HTML, CSS'))
    const project6 = {
        name: tProject6.name,
        description: tProject6.description,
        info: tProject6.info,
        date: '01/2021 - 02/2021',
        demo: [
            {
                type: 'live',
                link: 'https://zlval.pt'
            }
        ],
        img: Portfolio,
        type: 'pc'
    }

    const tProject5 = t('Projects.allProjects.5', { returnObjects: true })
    tProject5.info.push(getTecnologies('Microsoft Visual Express 2013, C++'))
    const project5 = {
        name: tProject5.name,
        description: tProject5.description,
        info: tProject5.info,
        date: '07/2020 - 07/2020',
        demo: [
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/Expresso'
            }
        ],
        img: Expresso,
        type: 'pc'
    }

    const tProject4 = t('Projects.allProjects.4', { returnObjects: true })
    tProject4.info.push(getTecnologies('Microsoft Visual Express 2013, C++'))
    const project4 = {
        name: tProject4.name,
        description: tProject4.description,
        info: tProject4.info,
        date: '04/2020 - 04/2020',
        demo: [
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/VendingMachine'
            }
        ],
        img: VendingMachine,
        type: 'pc'
    }

    const tProject3 = t('Projects.allProjects.3', { returnObjects: true })
    tProject3.info.push(getTecnologies('Symfony, PHP, JavaScript, JQuery, HTML, CSS'))
    const project3 = {
        name: tProject3.name,
        description: tProject3.description,
        info: tProject3.info,
        date: '12/2019 - 01/2020',
        demo: [
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/poolprime'
            }
        ],
        img: PoolPrime,
        type: 'pc'
    }

    const tProject2 = t('Projects.allProjects.2', { returnObjects: true })
    tProject2.info.push(getTecnologies('Android Studio, Java, SQL, PHP'))
    const project2 = {
        name: tProject2.name,
        description: tProject2.description,
        info: tProject2.info,
        date: '09/2018 - 01/2019',
        demo: [
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/oCeanClean---App'
            },
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/oCeanClean---Web'
            },
            {
                type: 'video',
                link: '/projects/videos/oCeanClean.mp4'
            }
        ],
        img: '/projects/img/oceanclean.png',
        type: 'mobile'
    }

    const tProject1 = t('Projects.allProjects.1', { returnObjects: true })
    tProject1.info.push(getTecnologies('HTML, PHP, JavaScript, JQuery, SQL'))
    const project1 = {
        name: tProject1.name,
        description: tProject1.description,
        info: tProject1.info,
        date: '10/2018 - 10/2018',
        demo: [
            {
                type: 'github',
                link: 'https://github.com/zlval-dev/FiftyOneMedia'
            }
        ],
        img: FiftyOneMedia,
        type: 'pc'
    }
    
    return [
        project6,
        project5,
        project4,
        project3,
        project2,
        project1
    ]
}

export default ProjectsConstants