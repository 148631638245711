import './App.css';
import NavBar from './components/NavBar'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import React from 'react'
import AboutHome from './components/AboutHome'
import ServicesHome from './components/ServicesHome'
import SkillsHome from './components/SkillsHome'
import Footer from './components/Footer'
import About from './components/About'
import Skills from './components/Skills'
import Projects from './components/Projects'
import Cv from './components/Cv'
import NotFoundPage from './components/NotFoundPage'

const currentPage = window.location.pathname.replaceAll('/', '')

const pagesWithoutHeaderAndFooter = [
  'cv'
]

function App() {  
  return (
    <BrowserRouter>
      {
        !pagesWithoutHeaderAndFooter.includes(currentPage) && <NavBar />
      }
      <Switch>
        <Route exact path="/">
          <AboutHome />
          <ServicesHome />
          <SkillsHome />
        </Route>
        <Route exact path="/about" component={ About } />
        <Route exact path="/skills" component={ Skills } />
        <Route exact path="/projects" component={ Projects } />
        <Route exact path="/projects/player" component={ Projects } />
        <Route exact path="/404" component={ NotFoundPage } />
        <Route exact path="/cv" component={ Cv } />
        <Redirect to="/404" />
      </Switch>
      {
        !pagesWithoutHeaderAndFooter.includes(currentPage) && <Footer />
      }
    </BrowserRouter>
  )
}

export default App;
