import React, { useEffect, useRef, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlay, faPause, faWindowClose} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import '../css/videoplayer.css'

function useStatePlayer($video){
    const [playerState, setPlayerState] = useState({
        playing: false,
        percentage: 0
    })

    useEffect(() => {
        const $play = document.querySelector('.player-play')
        const $pause = document.querySelector('.player-pause')
        if(!playerState.playing){
            $video.current.pause()
            $pause.style.display = 'none'
            $play.style.display = 'inline'
        }else{
            $video.current.play()
            $play.style.display = 'none'
            $pause.style.display = 'inline'
        }
    },[playerState.playing])

    const handleClickVideo = () => {
        setPlayerState({
            ...playerState,
            playing: !playerState.playing
        })
    }

    const restartVideo = () => {
        setPlayerState({
            playing: !playerState.playing,
            percentage: 0
        })
    }

    const handleTimeUpdate = () => {
        const currentPercentage = ($video.current.currentTime / $video.current.duration) * 100
        setPlayerState({
            ...playerState,
            percentage: currentPercentage
        })
    }

    const handleChangeVideoPercentage = (event) => {
        const currentPercentageValue = event.target.value
        $video.current.currentTime = ($video.current.duration / 100) * currentPercentageValue
    }

    return{
        playerState,
        handleClickVideo,
        handleTimeUpdate,
        handleChangeVideoPercentage,
        restartVideo
    }
}

const VideoPlayer = ({video}) => {

    if(video === ''){
        window.location.href = '/projects'
    }

    useEffect(() => {
        document.querySelector('body').classList.add('stopScroll')
    }, [])

    const $video = useRef(null)
    const {
        playerState,
        handleClickVideo,
        handleTimeUpdate,
        handleChangeVideoPercentage,
        restartVideo
    } = useStatePlayer($video)

    return (
        <div className="video-player">
            <Link to="/projects" style={{color: '#fff'}} onClick={() => {document.querySelector('body').classList.remove('stopScroll')}}><FontAwesomeIcon icon={faWindowClose} className="player-close" /></Link>
            <video ref={$video} src={video} onEnded={restartVideo} onTimeUpdate={handleTimeUpdate} playsInline></video>
            <div className="controls">
                <FontAwesomeIcon icon={faPlay} className="player-play" onClick={handleClickVideo} />
                <FontAwesomeIcon icon={faPause} className="player-pause" onClick={handleClickVideo} />
                <input type="range" min="0" max="100" className="video-time" onChange={handleChangeVideoPercentage} value={playerState.percentage} />
            </div>
        </div>
    )
}

export default VideoPlayer