import React from 'react'
import Profile from '../img/profile.jfif'
import '../css/about.css'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import moment from "moment"
import { ThemeContext } from 'styled-components'

const About = () => {

    $('.navbar ul > *').removeAttr('id')
    $('.navbar ul > *:nth-child(1)').attr('id', 'active')

    const { t } = useTranslation()

    var currentDate = {day: moment().format("DD"), month: moment().format("MM"), year: moment().format("YYYY")}
    const getAge = () => {
        var birthDate = {day: "01", month: "09", year: "1998"}
        var months = currentDate.month - birthDate.month
        if(months < 0 || (months === 0 && currentDate.day < birthDate.day)){
            return (currentDate.year - birthDate.year) - 1
        }
        return currentDate.year - birthDate.year
    }

    return(
        <div className="about">
            <h3>{ t('Home.1') }</h3>
            <div className="background-about-me">
                <div className="background-about-opacity">
                    <div className="about-me">
                        <img src={ Profile } alt="profile"/>
                        <div className="about-information">
                            <div className="about-page-cards">
                                <div>
                                    <p>João Valdemar Sousa Berenguer</p>
                                    <h3>{ t('Homepage.About.2') }</h3>
                                </div>
                                <div>
                                    <p>{ getAge() } { t('Homepage.About.4') }</p>
                                    <h3>{ t('Homepage.About.3') }</h3>
                                </div>
                                <div>
                                    <p>Madeira, Funchal</p>
                                    <h3>{ t('Homepage.About.5') }</h3>
                                </div>
                                <div>
                                    <p>{ t('Homepage.About.7') }</p>
                                    <h3>{ t('Homepage.About.6') }</h3>
                                </div>
                            </div>
                            <div className="about-page-resume">
                                {
                                    Object.values(t('Home.info', { returnObjects: true })).map((text, key) => (
                                        <p key={key}>{ text }</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-formation">
                <div className="h2-background">
                    <h2>{ t('Home.5') }</h2>
                </div>
                {
                    Object.values(t('Home.formation', { returnObjects: true })).map((text, key) => (
                        <div className="card-formation" key={key}>
                            <h4>{ text.subtitle } | { text.date }</h4>
                            <h5>{ text.title }, { text.info }</h5>
                        </div>
                    ))
                }
            </div>
            <div className="about-experience">
                <div className="h2-background">
                    <h2>{ t('Home.8') }</h2>
                </div>
                {
                    Object.values(t('Home.experience', { returnObjects: true })).map((text, key) => (
                        <div className="card-experience" key={key}>
                            <h4>{ text.title } | { text.subtitle }</h4>
                            <h5>{ text.date }</h5>
                            { typeof text.info !== 'undefined' && 
                            <ul>
                                { Object.values(text.info).map((el, key) => (
                                    <li key={key}><FontAwesomeIcon icon={ faLocationArrow } />{ el }</li>
                                )) }
                            </ul> }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default About